import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import { Bars } from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form } from 'formik';
import JSONPretty from 'react-json-prettify';
import FieldValue from './FieldValue'
import Debug from '../Debug';
import Personal from './Personal';
import PersonalForm from './PersonalForm';
import Address from './Address';
import AddressForm from './AddressForm';
import WorkForm from './WorkForm';
import Work from './Work';
import { MyDropzoneFormik } from './MyDropzone'

import * as Yup from 'yup'
import FormikControl from '../forms/FormikControl'


const ProspectCreate = (props) => {

    const initValue = {
        "zProspectoID": "",
        "tracking": { "DP": "current" },
        "PrimerNombre": "",
        "SegundoNombre": "",
        "ApellidoPaterno": "",
        "ApellidoMaterno": "",
        "NombreCompleto": "",
        "Sexo": "",
        "EsJubilado": "",
        "EsPensionado": "",
        "EstaAMPYME": "",
        "PagaFECI": "",
        "Correo": "",
        "FechaNacimiento": "",
        "Cedula": "",
        "RUC": "",
        "Nacion": "",
        "PaisResidencia": "PA",
        "TelefonoCelular": "",
        "Telefono": "",
        "EstadoCivil": "",
        "sub_activity": "",
        "facebook": "",
        "client_type": "",
        "subclass_client": "",
        "business_name": "",
        "employees_count": "",
        "FechaIniTrabajo": "",
        "Puesto": "",
        "ExtTelefonoTrab": "",
        "CorreoAlterPM": "",
        "website": "",
        "business_type": "",
        "ClasificacionActividad": "",
        "Clasificacion": "",
        "instagram": "",
        "twitter": "",
        "other_social": "",
        "tiktok": "",
        "TipoMotivo": "",
        "workflow_state": 'DP',
        "DirecProspectos": []
    }

    const [prospect, setProspect] = useState();
    const [currentUser, setCurrentUser] = useState({});
    const [loading, setLoading] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [allowed, setAllowed] = useState(true);
    const [sucursales, setSucursales] = useState([]);
    const [users, setUsers] = useState([]);
    const [isClicked, setIsClicked] = useState(false);

    const formRef = useRef(null);

    const validationSchema = Yup.object({
        TelefonoCelular: Yup.string()
            .required('Requerido'),
        PrimerNombre: Yup.string()
            .required('Requerido'),
        ApellidoPaterno: Yup.string()
            .required('Requerido'),
        TipoMotivo: Yup.string()
            .required('Requerido'),
        Correo: Yup.string()
            .email('Formato inválido de correo')

    })


    const getSucursales = () => {
        const token = document.querySelector('[name=csrf-token]').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token
        axios.get(`/api/v1/sucursales`)
            .then(resp => {
                setSucursales(resp.data.items);
            })
            .catch(error => { return error });
    };

    const getUsers = (sucursalID) => {
        const token = document.querySelector('[name=csrf-token]').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token
        axios.get(`/api/v1/users?sucursalID=${sucursalID}`)
            .then(resp => {
                setUsers(resp.data.users);
            })
            .catch(error => { return error });
    };

    const postFiles = (prospectoID, acceptedFiles) => {
        let formData = new FormData();

        for (var i = 0; i < acceptedFiles.length; i++) {
            let file = acceptedFiles[i];
            formData.append('files[]', file);

            return axios.post(`/api/v1/prospectos/${prospectoID}/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(res => res.data)
        }
    }

    const onSubmit = values => {
        // setProspect(values);
        setIsClicked(true)

        const token = document.querySelector('[name=csrf-token]').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token
        axios.post(`/api/v1/prospectos`, { prospecto: values })
            .then(resp => {
                // postFiles(resp.data.ProspectoID, values.files);
                location.replace(`/prospects/${resp.data.ProspectoID}`);
            })
            .catch(data => console.log('error', data))
    }

    const getCurrentUser = () => {
        const token = document.querySelector('[name=csrf-token]').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token
        axios.get(`/api/v1/users/current`)
            .then(resp => {
                // console.log(resp.data);
                // formik.setFieldValue("SucursalID", resp.data.SucursalUsuario);
                // formik.setFieldValue("responsible_id", resp.data.UsuarioID);

                getUsers(resp.data.SucursalUsuario);
                setCurrentUser(resp.data)
                const cu = { ...initValue, ...{ "SucursalID": resp.data.SucursalUsuario, "responsible_id": resp.data.UsuarioID } };
                // console.log('getProspect',cu);
                setProspect(cu);
            })
            .catch(error => { return error });
    };


    useEffect(() => {

        void async function fetchData() {
            // setLoading(true);
            await getSucursales();
            await getCurrentUser();
            // setLoading(false);
        }();
    }, [refresh]);


    const Actions = (props) => {
        const action_to_certification = () => putState('CD');

        const putState = (state) => {
            const p = { ...props.values, ...{ workflow_state: state } }
            onSubmit(p);
            setProspect(p);
        };


        const actionsButtons = () => {
            return (
                <div>

                </div>
            );
        };


        return (
            <div className="px-2 py-4 sm:px-6">
                <span className="mr-2 inline-flex rounded-md shadow-sm">
                    <button type="button" className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                        <a href={`/prospects`} >
                            Salir
                        </a>
                    </button>
                </span>
                <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                    <button
                        type="submit"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                        disabled={isClicked}
                    >
                        {isClicked ? "Salvando..." : "Crear Prospecto"}
                    </button>
                </span>


            </div>
        )
    }

    return (
        <>
            {!prospect ?
                <div className="flex h-screen">
                    <div className="m-auto">
                        <Bars
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={20000} //3 secs

                        />
                    </div>
                </div>
                :
                <div className="overflow-y-auto">
                    <Formik
                        initialValues={prospect}
                        // initialValues={getCurrentUser}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {formik => {

                            return (
                                <Form>
                                    <PersonalForm  {...formik} sucursales={sucursales} users={users} getUsers={getUsers} savedEnabled={false} />
                                    {/* <MyDropzoneFormik {...formik} /> */}
                                    <Actions {...formik} />
                                    {/* <Debug  {...formik}/> */}
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            }

        </>
    );
}

export default ProspectCreate
