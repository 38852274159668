import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios'
import { Bars } from 'react-loader-spinner'
// import Template from '../Template'

import Moment from 'moment';
import { Formik, Field, FieldArray, Form, yupToFormErrors } from 'formik';
import JSONPretty from 'react-json-prettify';
import FieldValue from './FieldValue'
import Debug from '../Debug';
import DetalleProspecto from './DetalleProspecto';
import Personal from './Personal';
import PersonalForm from './PersonalForm';
import Personal2 from './Personal2';
import PersonalForm2 from './PersonalForm2';
import Address from './Address';
import AddressForm from './AddressForm';
import ContactForm from './ContactForm';
import WorkForm from './WorkForm';
import Work from './Work';
import Documents from './Documents';
import AuthAPC from './AuthAPC';
import ConsultAPC from './ConsultAPC';
import Other from './Other';
import ExpressQuote from './ExpressQuote';
import Referral from './Referral';
import ReferralForm from './ReferralForm';

import * as Yup from 'yup'
import FormikControl from '../forms/FormikControl'


const Prospect = (props) => {
    // const [legalUsers, setLegalUsers] = useState([]);
    // const [operationsUsers, setOperationslUsers] = useState([]);
    const [prospect, setProspect] = useState({ "PaisResidencia": "PA" });
    const [action, setAction] = useState([]);
    const [currentUser, setCurrentUser] = useState([]);
    const [loading, setLoading] = useState([]);
    const [loadingAPC, setLoadingAPC] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [allowed, setAllowed] = useState(true);
    const [signature, setSignature] = useState();
    const [signatureDate, setSignatureDate] = useState();
    // const [apcEnabled, setApcEnabled] = useState(false);
    const [apcScoreRecords, setApcScoreRecords] = useState([]);
    const [sucursales, setSucursales] = useState([]);
    const [users, setUsers] = useState([]);

    const [personalEdit, setPersonalEdit] = useState(false);
    const [personalEdit2, setPersonalEdit2] = useState(false);
    const [workEdit, setWorkEdit] = useState(false);
    const [addressEdit, setAddressEdit] = useState(false);
    const [authApcEdit, setAuthApcEdit] = useState(false);
    const [referralEdit, setReferralEdit] = useState(false);
    const [documentEdit, setDocumentEdit] = useState(false);
    const [consultApcEdit, setConsultApcEdit] = useState(false);
    const [otherEdit, setOtherEdit] = useState(false);
    const [contactEdit, setContactEdit] = useState(false);
    const [authSigned, setAuthSigned] = useState(false);
    const [canAuth, setCanAuth] = useState(false);

    const [dropdownGuaranteeType, setDropdownGuaranteeType] = useState([
        { key: 'Prendario', value: 'Prendario' },
        { key: 'Mix de Garantías', value: 'Mix de Garantías' },
        { key: 'Mobiliario', value: 'Mobiliario' },
        { key: 'Inmobiliario', value: 'Inmobiliario' }
    ]);

    const formRef = useRef(null);

    const validationSchema = Yup.object({
        // TipoMotivo: Yup.string()
        //     .required('Requerido'),
        TelefonoCelular: Yup.string()
            .required('Requerido'),
        PrimerNombre: Yup.string()
            .required('Requerido'),
        ApellidoPaterno: Yup.string()
            .required('Requerido'),
        Correo: Yup.string()
            .email('Formato inválido de correo'),
        DirecProspectos: Yup.array()
            .of(
                Yup.object().shape({
                    EstadoID: Yup.number()
                        .typeError('Debe seleccionar Provincia')
                        .required('Requerido'),
                    MunicipioID: Yup.number()
                        .typeError('Debe seleccionar Distrito')
                        .required('Requerido'),
                    LocalidadID: Yup.number()
                        .typeError('Debe seleccionar Corregimiento')
                        .required('Requerido'),
                    Colonia: Yup.string()
                        .required('Requerido')
                })
            ),
        Estatus: Yup.string(),
        TipoMotivo: Yup.string()
            .required('Requerido'),

        guarantee_type: Yup.string().when("Estatus", (estatus, schema) => {
            if (estatus != 'contact' && estatus != 'interest' && estatus != 'apc')
                return schema.required('Requerido')
            return schema
        }),

        // guarantee_type: Yup.string().when('Estatus', {
        //     is: (estatus, schema) => {
        //         console.log('Estatus',estatus);
        //         console.log('Schema',schema);
        //         estatus != 'contact' && estatus != 'interest'
        //     },
        //     then: Yup.string().required('Requerido'),
        //     otherwise: Yup.string().notRequired(),
        // })

        // guarantee_type: Yup.string()
        //     .required('Requerido'),
        // requested_amount: Yup.number()
        //     .required('Requerido'),
        // month_periods: Yup.number()
        //     .required('Requerido'),
        // interest_rate: Yup.number()
        //     .required('Requerido'),
        // comission_rate: Yup.number()
        //     .required('Requerido'),
        // secure_rate: Yup.number()
        //     .required('Requerido'),
        // handling_amount: Yup.number()
        //     .required('Requerido'),
        // legal_amount: Yup.number()
        //     .required('Requerido')
    })

    const getSucursales = () => {
        const token = document.querySelector('[name=csrf-token]').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token
        axios.get(`/api/v1/sucursales`)
            .then(resp => {
                setSucursales(resp.data.items);
            })
            .catch(error => { return error });
    };

    const getUsers = (sucursalID) => {
        const token = document.querySelector('[name=csrf-token]').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token
        axios.get(`/api/v1/users?sucursalID=${sucursalID}`)
            .then(resp => {
                setUsers(resp.data.users);
            })
            .catch(error => { return error });
    };




    const onSubmit = values => {
        // setProspect(values);
        console.log(values)
        setAction(null);
        const token = document.querySelector('[name=csrf-token]').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token
        axios.put(`/api/v1/prospectos/${values.ProspectoID}`, { prospecto: { ...values, ...{ signature: signature, signatureDate: signatureDate } } })
            .then(resp => {
                setProspect(resp.data.item);
                setPersonalEdit(false);
                setPersonalEdit2(false);
                setWorkEdit(false);
                setAddressEdit(false);
                setAuthApcEdit(false);
                setDocumentEdit(false);
                setReferralEdit(false);
                setConsultApcEdit(false);
                setOtherEdit(false);
                setContactEdit(false);
                setAuthSigned(resp.data.item.auth_signed);
                setCanAuth(resp.data.item.can_auth);

                // location.replace(`/prospects/${resp.data.ProspectoID}`);
            })
            .catch(data => console.log('error', data))
        // location.reload();
    }

    useEffect(() => {
        setLoading(true);
        setAction(props.match.params.action);
        axios.get('/api/v1/prospectos/' + props.match.params.id)
            .then(resp => {
                setProspect(resp.data.item);
                setSignature(resp.data.item.signature)
                getUsers(resp.data.item.SucursalID);
                setAuthSigned(resp.data.item.auth_signed);
                setCanAuth(resp.data.item.can_auth);
                setLoading(false);

            })
            .catch(data => console.log('error', data));

        getSucursales();

    }, [refresh]);


    const Actions = (props) => {

        const putState = (state) => {
            // props.submitForm();
            // props.validateForm();
            const p = { ...props.values, ...{ workflow_state: state } }
            onSubmit(p);
            setProspect(p);
        };

        const putState2 = (state) => {
            const token = document.querySelector('[name=csrf-token]').content
            axios.defaults.headers.common['X-CSRF-TOKEN'] = token

            if (formRef.current) {
                formRef.current.handleSubmit();
            }
            axios.put(`/api/v1/prospectos/${props.prospect.ProspectoID}`, { prospecto: { workflow_state: state } })
                .then(resp => setProspect(resp.data.item))
                .catch(data => console.log('error', data))
        };


        const actionsButtons = () => {
            return (
                <div>

                </div>
            );
        };

        return (
            <div className="px-2 py-4 sm:px-6">
                <span className="mr-2 inline-flex rounded-md shadow-sm">
                    <button type="button" className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
                        <a href={`/prospects`} >
                            {/* <Link to={`${props.path}/${prospect.ProspectoID}`}> */}
                            Salir
                            {/* </Link>     */}
                        </a>
                    </button>
                </span>
                {prospect.workflow_state == "DP" &&
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="submit" onClick={() => { props.setFieldValue("workflow_state", "DD") }}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                        >
                            Avanzar
                        </button>
                    </span>
                }
                {prospect.workflow_state == "DD" &&
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="submit" onClick={() => { props.setFieldValue("workflow_state", "DP") }} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                            Regresar
                        </button>
                    </span>
                }
                {prospect.workflow_state == "DD" &&
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button
                            type="submit"
                            onClick={() => { props.setFieldValue("workflow_state", "AP") }}
                            disabled={!(props.isValid && (props.values.DirecProspectos && props.values.DirecProspectos.length > 0))}
                            className={`inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white ${props.isValid && (props.values.DirecProspectos && props.values.DirecProspectos.length > 0) ? "bg-indigo-600 hover:bg-indigo-500" : "bg-indigo-200 hover:bg-indigo-200"}  focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150`}
                        >
                            Avanzar
                        </button>
                    </span>
                }
                {prospect.workflow_state == "AP" &&
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="submit" onClick={() => { props.setFieldValue("workflow_state", "DD") }} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                            Regresar
                        </button>
                    </span>
                }
                {prospect.workflow_state == "AP" &&
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button
                            type="submit"
                            onClick={() => { props.setFieldValue("workflow_state", "DC") }}
                            disabled={!(signature || props.values.apcEnabled)}
                            className={`inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white ${signature || props.values.apcEnabled ? "bg-indigo-600 hover:bg-indigo-500" : "bg-indigo-200 hover:bg-indigo-200"}  focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150`}
                        >
                            Avanzar
                        </button>
                    </span>
                }
                {prospect.workflow_state == "DC" &&
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="submit" onClick={() => { props.setFieldValue("workflow_state", "AP") }} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                            Regresar
                        </button>
                    </span>
                }
                {prospect.workflow_state == "DC" &&
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button
                            type="submit"
                            onClick={() => { props.setFieldValue("workflow_state", "OD") }}
                            disabled={!(!props.values.apcEnabled || props.values.requiredDocuments.includes("Autorización APC"))}
                            className={`inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white ${!props.values.apcEnabled || props.values.requiredDocuments.includes("Autorización APC") ? "bg-indigo-600 hover:bg-indigo-500" : "bg-indigo-200 hover:bg-indigo-200"}  focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150`}
                        >
                            Avanzar
                        </button>
                    </span>
                }
                {prospect.workflow_state == "OD" &&
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="submit" onClick={() => { props.setFieldValue("workflow_state", "DC") }} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                            Regresar
                        </button>
                    </span>
                }
                {prospect.workflow_state == "OD" &&
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="submit" onClick={() => { props.setFieldValue("workflow_state", "C") }} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                            Salvar
                        </button>
                    </span>
                }
                {prospect.workflow_state == "C" &&
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="submit" onClick={() => { props.setFieldValue("workflow_state", "OD") }} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                            Regresar
                        </button>
                    </span>
                }
                {prospect.workflow_state == "C" &&
                    <span className="mx-2 my-2 inline-flex rounded-md shadow-sm">
                        <button type="submit" onClick={() => { props.setFieldValue("workflow_state", "C") }} className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                            Salvar
                        </button>
                    </span>
                }

            </div>
        )
    }

    return (
        <>
            {loading ?
                <div className="flex h-screen">
                    <div className="m-auto">
                        <Bars
                            color="#00BFFF"
                            height={100}
                            width={100}
                            timeout={20000} //3 secs

                        />
                    </div>
                </div>
                :
                <div className="overflow-y-auto">
                    <Formik
                        enableReinitialize={true}
                        initialValues={prospect}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {formik => {
                            const getAPC = () => {
                                setLoadingAPC(true);
                                const token = document.querySelector('[name=csrf-token]').content
                                axios.defaults.headers.common['X-CSRF-TOKEN'] = token
                                axios.get(`/api/v1/prospectos/${props.match.params.id}/get_apc`)
                                    .then(resp => {
                                        setLoadingAPC(false);
                                        formik.setFieldValue('apc_scores_with_empty', resp.data.item.apc_scores_with_empty);

                                    })
                                    .catch(error => { return error });
                            }

                            return (
                                <Form>
                                    {
                                        personalEdit
                                            ? <PersonalForm  {...formik} sucursales={sucursales} users={users} getUsers={getUsers} savedEnabled={true} />
                                            : <div>
                                                <DetalleProspecto prospect={prospect} />
                                                <Personal prospect={prospect} setPersonalEdit={setPersonalEdit} />
                                            </div>
                                    }
                                    {(formik.values.Estatus == 'interest' || formik.values.Estatus == 'apc' || formik.values.Estatus == 'estimate' || formik.values.Estatus == 'acceptance' || formik.values.Estatus == 'request') &&
                                        <div>
                                            {
                                                personalEdit2
                                                    ? <PersonalForm2  {...formik} sucursales={sucursales} users={users} getUsers={getUsers} />
                                                    : <Personal2 prospect={prospect} setPersonalEdit={setPersonalEdit2} />
                                            }
                                            {
                                                addressEdit
                                                    ? <AddressForm  {...formik} />
                                                    : <Address prospect={prospect} setAddressEdit={setAddressEdit} />
                                            }
                                            {false &&
                                                <Other  {...formik} editable={otherEdit} setOtherEdit={setOtherEdit} />
                                            }
                                            {formik.values.TipoMotivo == "Referido" &&
                                                (
                                                    referralEdit
                                                        // ? <ReferralForm  {...formik}  prospect={formik.values} ref={formRef} setReferralEdit={setReferralEdit} />
                                                        ? <ReferralForm  {...formik} prospect={formik.values} onSubmit={onSubmit} />
                                                        : <Referral prospect={prospect} setReferralEdit={setReferralEdit} />
                                                )

                                            }
                                        </div>
                                    }

                                    {(formik.values.Estatus == 'apc' || formik.values.Estatus == 'estimate' || formik.values.Estatus == 'acceptance' || formik.values.Estatus == 'request') &&
                                        <div>
                                            <AuthAPC  {...formik} signature={signature} setSignature={setSignature} signatureDate={signatureDate} setSignatureDate={setSignatureDate} editable={authApcEdit} setAuthApcEdit={setAuthApcEdit} canAuth={canAuth} />
                                            <Documents  {...formik} editable={documentEdit} setDocumentEdit={setDocumentEdit} />
                                        </div>
                                    }
                                    {(formik.values.Estatus == 'apc' || formik.values.Estatus == 'estimate' || formik.values.Estatus == 'acceptance' || formik.values.Estatus == 'request') &&
                                        <ConsultAPC  {...formik}
                                            apc_scores={formik.values.apc_scores_with_empty}
                                            voidAuth={false}
                                            editable={consultApcEdit}
                                            getAPC={getAPC}
                                            loadingAPC={loadingAPC}
                                            setConsultApcEdit={setConsultApcEdit}
                                            authSigned={authSigned}
                                        />
                                    }
                                    {(formik.values.Estatus == 'estimate' || formik.values.Estatus == 'acceptance' || formik.values.Estatus == 'request') &&
                                        <div>
                                            <ExpressQuote  {...formik} editable={otherEdit} setOtherEdit={setOtherEdit} dropdownGuaranteeType={dropdownGuaranteeType} setDropdownGuaranteeType={setDropdownGuaranteeType} />
                                            {
                                                workEdit
                                                    ? <WorkForm  {...formik} />
                                                    : <Work prospect={prospect} setWorkEdit={setWorkEdit} />
                                            }
                                            <ContactForm  {...formik} contactEdit={contactEdit} setContactEdit={setContactEdit} />
                                        </div>
                                    }


                                    {/* <Debug  {...formik}/> */}
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            }
            {/* <pre>{ JSON.stringify(props.prospect, null, 2) }</pre> */}

        </>
    );

}

export default Prospect
